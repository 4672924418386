<template>
  <div id="app">
    <!--      <KeepAlive>-->
    <router-view/>
    <!--      </KeepAlive>-->
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
export default{
  name:'app',
  components:{ Header , Footer },
}
</script>

<style lang="scss" scoped>
@import url(./assets/css/reset.scss);
@import url(./assets/css/common.scss);
// 简单适配手机端
#app{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
