<template>
  <div class="footer">
      <div class="footer_list">
        <div class="footer_list_item" v-for="(item,index) in footerList" :key="index">
          <img :src="item.iconUrl" alt="">
          <span>{{ item.msg }}</span>
        </div>
        <div class="public">
          <img src="../../static/footer_erweima.png"/>
          <span>扫一扫，关注微信公众号</span>
        </div>
      </div>
    <div class="filingInfo">
      湖南岳泰兴医疗科技有限公司
      <span class="filingNum" @click="go">湘ICP备19022900号-1</span>
    </div>
   </div>
</template>

<script>
export default{
  name:'Footer',
  data(){
    return({
      footerList:[
        {
          iconUrl:require('@/assets/img/home/footer_local.png'),
          msg:'湖南省长沙市雨花区金海路158号'
        },{
          iconUrl:require('@/assets/img/home/footer_tel.png'),
          msg:'400-022-9558'
        },{
          iconUrl:require('@/assets/img/home/footer_post.png'),
          msg:'dwychina@163.com'
        },{
          iconUrl:require('@/assets/img/home/footer_colock.png'),
          msg:'9:00-22:00'
        }
      ]
    })
  },
  methods:{
    go(){
      window.location.href='https://beian.miit.gov.cn'
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  height: 262px;
  background: #0f0f0f;
  //background: url(../../assets/img/home/footer_bg.jpg) repeat-x;
  color: #b7b7bb;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .footer_list{
    margin: 0 auto;
    display: flex;
    height: 80%;
    width: 1500px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 100px;
    .footer_list_item{
      display: flex;
      align-items: center;
      font-size: 16px;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .public{
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        color: #fff;
        font-size: 14px;
        margin-top: 5px;
      }
      img{
        width: 120px;
        height: 120px;
      }
    }
  }
  .filingInfo{
    padding-top: 20px;
    border-top: 1px solid #636363;
    height: 18%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    .filingNum{
      cursor: pointer;
      margin-left: 5px;
      color: orange;
    }
  }
}
</style>