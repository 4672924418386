import { isElementNotInViewport } from "../utils/checkClientRect"
import { mapMutations , mapGetters } from "vuex";
import { getAnimationClassName } from "../utils/getAnimationClassName"; 
export const mixins = {
  mounted () {
    this.$nextTick(()=>{
      let timer;
      // this.closeAnimation()
      window.addEventListener("scroll",()=>{
        if(timer) clearTimeout(timer)
        timer = setTimeout(() => {
          this.handleScroll()
        },200)
      })
      this.handleScroll()
    })
  },
  computed: {
    ...mapGetters([
      'homeContentTop',
      'homeCard'
      ,'categoryRight'
      ,'infoCard',
      'brand_top_msg'
      ,'brand_attestation_list'
      ,'swiper_img_list',
      'enterprise_center_container'
      ,'form',
      'franchise_right_msg'])
  },
  methods: {
    ...mapMutations(['openAnimationAll','closeAnimationAll']),
    handleScroll(){
      if(!this.$refs.animation) return
      if(!isElementNotInViewport(this.$refs.animation)){
        let animationClassName = getAnimationClassName(this.$refs.animation.getAttributeNode('class') || 'hhhh')
        this.$store.commit('openAnimationAll',animationClassName)
      }
    },
    closeAnimation(){
      if(this.homeContentTop) {
        this.$store.commit('closeAnimationAll','homeContentTop')
      }
      if(this.homeCard) {
        this.$store.commit('closeAnimationAll','homeCard')
      }
      if(this.categoryRight) {
        this.$store.commit('closeAnimationAll','categoryRight')
      }
      if(this.infoCard) {
        this.$store.commit('closeAnimationAll','infoCard')
      }
      if(this.brand_top_msg) {
        this.$store.commit('closeAnimationAll','brand_top_msg')
      }
      if(this.brand_attestation_list) {
        this.$store.commit('closeAnimationAll','brand_attestation_list')
      }
      if(this.enterprise_center_container) {
        this.$store.commit('closeAnimationAll','enterprise_center_container')
      }
      if(this.form) {
        this.$store.commit('closeAnimationAll','form')
      }
      if(this.franchise_right_msg) {
        this.$store.commit('closeAnimationAll','franchise_right_msg')
      }
    }
  }
}