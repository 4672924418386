<template>
  <div ref="animation" :class="['info_card',cardInfo.reverse?'reverse':'',infoCard?'animation_right_start':'animation_opacity']">
    <div class="card_left">
      <img :src="cardInfo.infoPic" alt="">
    </div>
    <div class="card_right">
      <div class="card_order">{{ cardInfo.order }}</div>
      <div class="card_title">{{ cardInfo.title }}</div>
      <div class="card_msg">
        {{ cardInfo.message }}
      </div>
      <div class="more_button" @click="moreEnterprise" v-show="cardInfo.more">
        查看更多
      </div>
    </div>
  </div>
</template>

<script>
import { mixins } from '@/mixin'
export default {
  name: 'infocard',
  mixins:[mixins],
  props: {
    cardInfo: {
      type: Object,
      default: () => {
        return ({
          infoPic: require('../assets/img/card/index_card_1.jpg'),
          order: 'A',
          title:'区域医疗服务中心',
          message: `区域医疗中心聚焦于健康产业的第三方服务，辅助医疗机构进行先进医疗技术的普及和推广，帮助基础医疗机构提升诊疗水平，夯实分级医疗的技术基础，同时，通过规模化和集约化运作，优化医疗资源配置，节约不必要的医疗资源耗费。`,
          moreUrl: '',
          reverse: false,
          more:true
        })
      }
    },
  },
  methods:{
    moreEnterprise(){
      const routeUrl = this.$router.resolve({
        path: "/newMore",
        // query: {id:67},
      });
      window.open(routeUrl.href, "_blank");
    }
  }
}
</script>

<style scoped lang="scss">
.reverse {
  flex-direction: row-reverse;
}

.info_card {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  width: 1200px;

  .card_left {
    position: relative;
    top: 0;
    left: 0;
    width: 548px;
    height: 418px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .card_right {
    position: relative;
    top: 0;
    height: auto;
    width: 546px;
    margin-right: 50px;

    .card_order {
      font-weight: 700;
      color: #f0f6f6;
      font-size: 115px;

    }

    .card_title {
      font-size: 24px;
      color: $font_light_color;
    }

    .card_msg {
      margin: 30px 0;
      font-size: 14px;
      color: #666666;
      line-height: 30px;
    }

    .more_button {
      box-sizing: border-box;
      width: 100px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 14px;
      color: $font_light_color;
      border: 1px solid $font_light_color;
      cursor: pointer;
    }
  }
}</style>