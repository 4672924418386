<template>
  <div class="header">
    <div class="website_wrapper header_center">
      <div class="header_logo" @click="$router.push('/')">
<!--        <h1>岳泰兴医疗</h1>-->
        <img src="../../static/yuetaixingLogo.png"/>
      </div>
      <div class="header_list">
        <div class="header_list_item" :class="{ item_active: $route.name == item.name }" v-for="(item, index) in headerList"
          :key="index" @click="$router.push(item.path)">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return ({
      headerList: [
        {
          title: '首页',
          name:'home',
          path: '/'
        }, {
          title: '品牌理念',
          name:'brand',
          path: '/brand'
        }, {
          title: '企业资讯',
          name:'enterprise',
          path: '/enterprise',
        }, {
          title: '商务合作',
          name:'franchise',
          path: '/franchise'
        }, {
          title: '核心产品',
          // name:'about',
          // path: '/about'
          name:'newMore',
          path:'/newMore'
        },
        {
          title: '联系我们',
          name:'contactUs',
          path: '/contactUs'
        },
        {
          title: '人才招聘',
          name:'recruit',
          path: '/recruit'
        }
      ]
    })
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 120px;

  .header_center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_logo {
      font-size: 32px;
      color: #02b2b5;
      cursor: pointer;
      img{
        width: 200px;
        height: 100px;
      }
    }

    .header_list {
      display: flex;
      // width: 540px;
      height: 40px;
      text-align: center;
      line-height: 40px;

      .header_list_item {
        padding: 0 10px;
        margin-left: 10px;
        width: 80px;
        height: 40px;
        cursor: pointer;
      }

      .header_list_item:hover,
      .item_active {
        background: #02b2b5;
        color: #fff;
        border-radius: 20px;
      }
    }
  }
}
</style>