<template>
  <div :class="['card',homeCard?'animation_top_start':'animation_opacity']"  ref="animation">
    <!-- <img src="../assets/img/home/card_bg.jpg" alt="" style="width"> -->
    <div class="card_item letter" :class="{animation_top_start:flag}">{{cardInfo.desc}}</div>
    <div class="card_item card_icon">
      <img src="../assets/img/home/card_A.jpg" alt="" :class="{animation_top_little:flag}">
    </div>
    <div class="card_item card_title" :class="{animation_top_little:flag}">{{cardInfo.title}}</div>
    <div class="card_item card_en_msg" :class="{animation_top_little:flag}">
      <div>{{cardInfo.info}}</div>
    </div>
   </div>
</template>

<script>
import { mixins } from '@/mixin'
export default{
  name:'card',
  methods: {
  },
  mixins:[mixins],
  props:{
    cardInfo:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return({
      flag:false,
    })
  }
}
</script>

<style scoped lang="scss">
.card{
  // transition: all .3s;
  position: relative;
  width: 385px;
  height: 359px;
  background: url('../assets/img/home/card_bg.jpg') 100% 100% no-repeat;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .card_item{
    position: absolute;
    top: 20px;
    // left: 50%;

    // transform: translateX(-50%);
  }
  .letter{
    font-size: 30px;
    font-family: '微软雅黑';
    color: #ffffff;
    transition: all .2s;
  }
  .card_icon{
    top: 120px;
  }
  .card_title{
    top: 200px;
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 700;
  }
  .card_en_msg{
    top: 250px;
    font-size: 14px;
    color: #999999;
    div{
      width: 300px;
    }
  }
}
</style>