<template>
  <div class="cardList">
    <div v-for="(item,index) in cardInfo">
      <Card :cardInfo="item"></Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card'

export default {
  name: 'cardList',
  components: {
    Card
  },
  data() {
    return {
      cardInfo: [
        {
          desc: 'A',
          title: '国家高新技术企业',
          info: '国家重点扶持高新技术企业'
        },
        {
          desc: 'B',
          title: '数字健康领域值得信赖的服务提供者',
          info: '秉承“用科技推动人类健康”的理念，推动世界医疗健康事业发展'
        },
        {
          desc: 'C',
          title: '涵盖多种技术领域',
          info: '云计算、AI、大数据、移动互联网、物联网等技术领域'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.cardList {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 121px;
  left: 1px;
  width: 1200px;
  animation: 0s ease 0s 1 normal none running none;
}
</style>